.magazineContainer {
    padding: 0px 40px;
    margin-bottom: 20px;
    @media (max-width: 800px) {
        padding: 0px 20px;
    }
    & > img {
        width: 80%;
        margin: 40px 10% 0px;
        @media (max-width: 800px) {
            width: 100%;
            margin: 40px auto 0px;
        }
    }
}

.magazineDetailsContainer {
    padding: 0px 80px;
    @media (max-width: 800px) {
        padding: 0px 20px;
    }
    & > img {
        width: 100%;
        display: block;
        height: 500px;
        object-fit: cover;
        margin-top: 20px;
    }
    & > * {
        direction: ltr;
    }
    & > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        width: 100%;
        justify-content: space-between;
            & > p:nth-child(1) {
                font-family: Aviny;
                font-size: 28px;
                margin: 0px 0px 10px;
            }
        }
    // }
    // & > div:nth-child(2n) {
    //     flex-direction: row-reverse;
    // }
}