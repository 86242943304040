.menuContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0px 0px;
  width: 90%;
  margin: auto;
  @media (max-width: 800px) {
    display: none;
  }
  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    & > a > img {
      width: 24px;
      height: 24px;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    & > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > a > img {
        width: 71px;
        height: 71px;
        cursor: pointer;
      }
      & > div {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0px 25px;
        position: relative;
        & > img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }
        & > span {
          font-weight: bold;
          font-size: 15px;
          line-height: 31px;
          color: black !important;
        }
        & > span:hover {
          color: #e15a04 !important;
        }
        & > div {
          position: absolute;
          width: 771px;
          top: 31px;
          left: 0px;
          z-index: 100;
          background: #ffffff;
          border: 1px solid rgba(171, 171, 171, 0.24);
          box-sizing: border-box;
          border-radius: 8px;
          padding: 0px 0px 0px 25px;
          display: flex;
          flex-direction: row;
          & > div:nth-child(1) {
            padding: 20px 0px 0px 0px;
            border-right: 1px solid #f7f7f7;
            width: 220px;
            & > div:nth-child(1) {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 10px;
              & > span {
                font-family: Aviny;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                color: #666666;
                margin-right: 10px;
              }
            }
            & > div:not(div:nth-child(1)) {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 10px;
              height: 50px;
              padding: 0px 0px 0px 14px;
              width: calc(100% - 18px);
              & > span {
                font-family: Aviny;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: #000000;
                margin-right: 10px;
                cursor: pointer;
              }
            }
            & > div:not(div:nth-child(1)):hover {
              background-color: rgba(225, 90, 4, 0.15);
              border-left: 4px solid #d45a07;
            }
            & > .active {
              background-color: rgba(225, 90, 4, 0.15);
              border-left: 4px solid #d45a07;
            }
          }

          & > div:nth-child(2) {
            padding: 20px 0px 0px 0px;
            width: calc(100% - 221px);
            margin-left: 10px;
            & > span:nth-child(1) {
              font-family: Aviny;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              color: #666666;
            }
            & > div > div:nth-child(1) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 31px;
              cursor: pointer;
              & > p:nth-child(1) {
                color: #e15a04;
                font-size: 24px;
                margin: 0px 10px 10px 0px;
              }
              & > p:nth-child(2) {
                font-family: Aviny;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
                margin: 0px;
              }
            }
            & > div > div:nth-child(2) {
              display: flex;
              flex-direction: column;
              & > p {
                font-family: Aviny;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #525252;
                margin: 0px;
                cursor: pointer;
              }
            }
          }
        }
      }

      & > div:hover > * {
        color: #e15a04 !important;
      }

      & > a > div {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0px 25px;
        & > img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }
        & > span {
          font-weight: bold;
          font-size: 15px;
          line-height: 31px;
          color: black !important;
        }
        & > span:hover {
          color: #e15a04 !important;
        }
      }
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      & > a > div {
        margin-right: 20px;
        position: relative;
        & > img {
          width: 32px;
          height: 32px;
        }
        & > p {
          position: absolute;
          color: white;
          top: -10px;
          right: -12px;
          margin: 0px;
          font-size: 14px;
          font-weight: bold;
          background-color: #d45a07;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      & > div {
        margin-right: 20px;
        & > img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.menuContainerMobile {
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 0px 0px;
    width: 85%;
    margin: auto;
    direction: ltr;
    & > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;
      & > a > img {
        width: 52px;
        height: 52px;
        cursor: pointer;
      }
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > a > img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        & > a:nth-child(2) > img {
          margin: 0px 10px;
        }
      }
    }
    & > div:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      & > img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        margin-right: 10px;
      }
      & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        & a > div {
          position: relative;
        }
        & a > div > p {
          position: absolute;
          color: white;
          top: -10px;
          right: -8px;
          margin: 0px;
          font-size: 14px;
          font-weight: bold;
          background-color: #d45a07;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & > a > div > img {
          width: 24px;
          height: 24px;
          cursor: pointer;
          display: block;
        }
        & > a > div > img {
          margin: 0px 0px 0px 10px;
        }
        & > div > img {
          width: 24px;
          height: 24px;
          cursor: pointer;
          display: block;
        }
        & > div > img {
          margin: 0px 0px 0px 10px;
        }
      }
    }
  }
}

.toggleMenuContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 32px;
  width: calc(80%);
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #ccc;
  z-index: 1000;
  & > a > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    & > span {
      font-weight: 500;
      font-size: 18px;
      // margin-right: 5px;
      color: black;
      font-family: Aviny;
    }
    & > span:hover {
      color: #e15a04 !important;
    }
    & > img {
      width: 24px;
      height: 24px;
    }
  }
  & > a > span {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    color: black;
    cursor: pointer;
    font-family: Aviny;
  }
  & > a > span:hover {
    color: #e15a04 !important;
  }
  & > div:nth-child(1) > span {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    color: black;
    cursor: pointer;
    font-family: Aviny;
  }
  & > div:nth-child(1) {
    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0px 5px 10px;
      border: none;
      background-color: white;
      cursor: pointer;
      & > img {
        width: 24px;
        margin-right: 4px;
      }
      & > span {
        font-family: Aviny;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #515151;
        margin-right: 10px;
      }
    }
  }
  & > div:nth-child(1) > span:hover {
    color: #e15a04 !important;
  }
}

.noBuyingProductContainer {
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    width: 86px;
    height: 86px;
    border-bottom: 1px solid black;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  & > p:nth-child(2) {
    font-family: IRANYekan;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #bd945b;
    margin: 0px 0px 10px;
  }
  & > p:nth-child(3) {
    font-family: IRANYekan;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: black;
    margin: 0px 0px 10px;
  }
  & > a:nth-child(4) > button {
    width: 227px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid black;
    color: black;
    height: 40px;
    font-size: 18px;
    font-family: IRANYekan;
    border-radius: 8px;
    margin-top: 10px;
  }
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 4px 6px;
  position: relative;
  & > input {
    border: none;
    border-radius: 8px;
    width: calc(100% - 24px);
    font-family: IRANYekan;
    font-size: 18px;
  }
  & > input:focus {
    outline: none;
  }
  & > img {
    width: 24px;
    height: 24px;
  }
  & > .byCodeContainer {
    position: absolute;
    top: 50px;
    right: -10px;
    width: 450px;
    height: auto;
    direction: ltr;
    overflow-x: scroll;
    background-color: white;
    border-radius: 8px;
    z-index: 200;
    padding: 10px;
    box-shadow: 0 0 50px #ccc;
    & > p {
      font-size: 16px;
      margin: 0px 0px 12px;
      font-weight: bold;
    }
    & > div:nth-child(2) {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 20px;
      & > a > div {
        width: 300px;
        & > img {
          object-fit: contain;
          border-radius: 8px 8px 0px 0px;
        }
      }
    }
    & > div:last-child {
      display: grid;
      grid-template-columns: 50% 50%;
      margin-top: 8px;
      & > div > p {
        margin: 0px 0px 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 800px) {
    width: 200px;
    & > input {
      border: none;
      border-radius: 8px;
      width: calc(100% - 24px);
      font-family: IRANYekan;
      font-size: 14px;
    }
    & > .byCodeContainer {
      width: 300px;
      left: -30px;
      & > div:nth-child(2) {
        & > a > div {
          width: 200px;
          & > img {
            object-fit: contain;
            border-radius: 8px 8px 0px 0px;
          }
        }
      }
    }
  }
}
