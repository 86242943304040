.registerComplaintsContainer {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: ltr;
    padding: 40px 30px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
        margin: 0px auto 80px;
    }
    & > p {
        font-family: IRANYekan;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        margin: 0px 0px 40px;
        @media (max-width: 800px) {
            font-size: 12px;
            text-align: center;
        }
    }
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 60%;
        margin: 0px auto 20px;
        @media (max-width: 800px) {
            flex-direction: column;
            width: 100%;
            margin: 0px auto;
        }
        & > div {
            width: 48%;
            @media (max-width: 800px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    & > div:nth-child(4) {
        margin-bottom: 15px;
        & > div {
            width: 100%;
        }
    }
    & > div:nth-child(5) {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        & > button {
            border: 1px solid #E15A04;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 10px 40px;
            background-color: white;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 18px;
            color: #E15A04;
            cursor: pointer;
        }
    }
}