.lawsContainer {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: ltr;
    padding: 40px 30px;
    & > p {
        font-family: IRANYekan;
        font-style: normal;
        font-size: 18px;
        text-align: left;
        color: #000000;
        margin: 0px 0px 10px;
        @media (max-width: 800px) {
            font-size: 12px;
        }
        & > span {
            color: #C69C61;
        }
    }
    & > p:nth-child(7) {
        display: flex;
        flex-direction: column;
        margin: 20px 0px 30px;
        & > span {
            margin: 0px 0px 5px;
        }
    }
}