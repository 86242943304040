.orderGuideContainer {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: ltr;
    padding: 40px 30px;
    & > p {
        font-family: Aviny;
        font-size: 18px;
        text-align: left;
        color: #000000;
        margin: 0px;
        @media (max-width: 800px) {
            font-size: 14px;
        }
    }
    & > div {
        display: flex;
        flex-direction: row;
        margin: 40px auto;
        width: 70%;
        justify-content: space-between;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > img {
            width: 40%;
            display: block;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }
    & > img {
        width: 40%;
        display: block;
        margin: 40px auto;
        @media (max-width: 800px) {
            width: 70%;
        }
    }
    & > :not(div:last-child) > p {
        font-family: Aviny;
        font-style: normal;
        font-size: 18px;
        text-align: left;
        color: #000000;
        margin: 0px;
        @media (max-width: 800px) {
            font-size: 14px;
        }
        
    }
}