.cartContainer {
  margin-bottom: 20px;
  width: 80%;
  margin: 0px auto;
  & > p {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 20px auto 30px;
    border-bottom: 1px solid rgba(228, 228, 228, 0.63);
    direction: ltr;
    padding-bottom: 20px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    direction: ltr;
    margin-bottom: 30px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > div:first-child {
      display: flex;
      flex-direction: column;
      width: 48%;
      @media (max-width: 800px) {
        width: 100%;
      }
      & > p:nth-child(1) {
        font-family: Aviny;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 15px 0px;
        @media (max-width: 800px) {
          font-size: 24px;
        }
      }
      & > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        // align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        & > div {
          width: 48%;
        }
      }
      & > div:nth-child(4) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        & > div {
          width: 48%;
        }
      }
      & > div:nth-child(7) {
        margin: 20px 0px 0px;
      }
      & > div:nth-child(7) {
        display: flex;
        flex-direction: row;
        margin: 10px 0px;
        @media (max-width: 800px) {
          flex-direction: column;
        }
        & > p {
          font-family: Aviny;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          color: #000000;
          margin: 0px;
          @media (max-width: 800px) {
            font-size: 20px;
          }
        }
        & > p:nth-child(2) {
          margin-left: 30px;
          @media (max-width: 800px) {
            margin-left: 0px;
          }
        }
        & > select {
          border: 1px solid black;
          border-radius: 8px;
          padding: 5px 10px;
          margin-left: 30px;
          color: black;
          font-family: IRANYekan;
          cursor: pointer;
          @media (max-width: 800px) {
            margin-left: 0px;
          }
        }
      }
      & > p:nth-child(8) {
        margin: 0px;
      }
      & > p:nth-child(9) {
        font-family: Aviny;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        color: #000000;
        margin: 10px 0px 30px 0px;
        @media (max-width: 800px) {
          font-size: 20px;
        }
      }
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;
      width: 48%;
      @media (max-width: 800px) {
        width: 100%;
      }
      & > p:nth-child(1) {
        font-family: Aviny;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        color: #000000;
        margin: 0px 0px 15px 0px;
        @media (max-width: 800px) {
          font-size: 24px;
          margin-top: 10px;
        }
      }
      & > div:nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(5),
      :nth-child(6) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        & > p:first-child {
          font-family: Aviny;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          color: #000000;
          margin: 0px;
          @media (max-width: 800px) {
            font-size: 20px;
          }
        }
        & > p:last-child {
          font-family: IRANYekan;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: #000000;
          margin: 0px;
          @media (max-width: 800px) {
            font-size: 14px;
          }
        }
      }
      & > p:nth-child(7) {
        font-family: Aviny;
        font-size: 24px;
        color: #000000;
        margin: 10px 0px 50px;
        @media (max-width: 800px) {
          font-size: 20px;
        }
      }
      & > button:nth-child(8) {
        width: 228px;
        height: 52px;
        background: #059207;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: IRANYekan;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        border: none;
        margin-right: calc(100% - 228px);
        cursor: pointer;
        @media (max-width: 800px) {
          width: 70%;
          margin-right: 15%;
        }
      }
      & > button:nth-child(8):focus {
        outline: none;
      }
      & > button:nth-child(8):hover {
        background-color: white;
        border: 1px solid #059207;
        color: #059207;
      }
    }
  }
}

.cartItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(228, 228, 228, 0.63);
  padding-bottom: 25px;
  margin-bottom: 25px;
  direction: ltr;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  & > div:first-child {
    display: flex;
    flex-direction: row;
    @media (max-width: 800px) {
      flex-direction: column;
      width: 100%;
    }
    & > img {
      width: 235px;
      height: 180px;
      object-fit: cover;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    & > div {
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 15px;
      @media (max-width: 800px) {
        margin-left: 0px;
        height: auto;
      }
      & > p:nth-child(1) {
        font-family: Aviny;
        font-size: 30px;
        color: #000000;
        margin: 0px;
        @media (max-width: 800px) {
          font-size: 24px;
        }
      }
      & > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > p {
          font-family: IRANYekan;
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          margin: 0px 0px 10px;
          @media (max-width: 800px) {
            font-size: 14px;
          }
        }
        & > div:nth-child(2) {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border: 1px solid #e4e4e4;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 0px 10px;
          width: 150px;
          margin-left: 15px;
          height: 50px;
          @media (max-width: 800px) {
            padding: 5px 15px;
          }
          & > p {
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            margin: 0px;
            @media (max-width: 800px) {
              font-size: 14px;
            }
          }
          & > input:nth-child(2) {
            margin: 0px 15px;
            border: none;
            width: 50px;
            text-align: center;
            font-family: IRANYekan;
            height: 90%;
          }
          & > input:focus {
            outline: none;
          }
          & > p:nth-child(2) {
            margin: 0px 15px;
          }
          & > p:nth-child(1),
          :nth-child(3) {
            font-size: 28px;
            cursor: pointer;
            @media (max-width: 800px) {
              font-size: 20px;
            }
          }
        }
        & > select {
          height: 50px;
          margin-right: 10px;
          font-family: IRANYekan;
          border: 1px solid black;
          border-radius: 8px;
          padding: 0px 10px;
        }
      }
      & > div:nth-child(3) {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > p {
          margin: 0px;
          font-family: IRANYekan;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          @media (max-width: 800px) {
            font-size: 12px;
          }
        }
        & > p:last-child {
          margin-left: 10px;
        }
      }
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    height: 180px;
    justify-content: space-between;
    @media (max-width: 800px) {
      height: 130px;
      width: 100%;
    }
    & > div:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 800px) {
        margin-top: 10px;
      }
      & > p {
        font-family: IRANYekan;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        margin: 0px 10px 0px 0px;
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
      & > img {
        width: 24px;
        display: block;
        @media (max-width: 800px) {
          width: 20px;
        }
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > p:first-child {
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 18px;
        color: #767676;
        margin: 0px;
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
      & > p:last-child {
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        margin: 0px 0px 0px 10px;
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
    }
    & > a > button {
      width: 228px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: IRANYekan;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      background-color: white;
      color: #e45803;
      border: 1px solid #e45803;
      @media (max-width: 800px) {
        width: 80%;
        margin: 0px auto;
      }
    }
    & > a > button:focus {
      outline: none;
    }
    & > a > button:hover {
      background-color: #e45803;
      color: white;
    }
  }
}

.communicationWay {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  & > p {
    font-size: 0.875rem;
    margin: 0px 0px 0px 16px;
  }
  & > select {
    border: solid black 1px;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: IRANYekan;
  }
}

.countryNameContainer {
  display: flex;
  flex-direction: column;
  & > p {
    height: 38px;
    line-height: 38px;
    font-family: Aviny;
    font-size: 24px;
    margin: 0px 0px 6px;
  }
  & > select {
    border: 1px solid #e4e4e4;
    border-radius: 0.8px;
    font-size: 16px;
    line-height: 1.25rem;
    font-family: IRANYekan;
    height: 48px;
  }
}

.noOutline:focus {
  outline: none;
}
