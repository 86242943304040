.collapseBarContainer {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
  border-radius: 10px;
  margin-bottom: 30px;
  direction: ltr;
  padding: 24px 35px;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    & > span {
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
    & > img {
      width: 49px;
      cursor: pointer;
      @media (max-width: 800px) {
        width: 40px;
      }
    }
  }
  & > .responseOpen {
    display: block;
    color: #757575;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px 0px;
    transition: display ease-in 2000ms;
    @media (max-width: 800px) {
      font-size: 14px;
    }
  }
  & > .responseClose {
    display: none;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    direction: ltr;
    & > span {
      font-size: 48px;
      line-height: 47px;
      font-weight: bold;
      font-family: "Aviny";
      @media (max-width: 800px) {
        font-size: 36px;
      }
    }
    & > span:first-child {
      color: #c69c61;
    }
    & > span:last-child {
      margin-left: 8px;
    }
  }
  & > img {
    width: 600px;
    margin-bottom: 50px;
    @media (max-width: 800px) {
      width: calc(100% - 40px);
    }
  }
}

.toomarCartContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  height: 356px;
  margin: 0px auto 80px;
  background: #ffffff;
  box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
  border-radius: 10px;
  // padding-bottom: 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
  @media (max-width: 800px) {
    margin-bottom: 30px;
  }
  & > img {
    width: 100%;
    height: 239px;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
  }
  & > div {
    width: 100%;
  }
  & > .overflowTitle {
    height: 87px;
  }
  & > div > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    font-family: sans-serif;
    color: #000000;
    text-align: left;
    width: 78%;
    margin: 5px 20% 20px 10px;
    direction: ltr;
  }
  & > .footerCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    direction: ltr;
  }
  & > .footerCards > .productCode > span {
    font-weight: bold;
    font-size: 12px;
    line-height: 31px;
    color: #000000;
    font-family: sans-serif;
  }
  & > .footerCards > .price {
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: 100%;
    justify-content: flex-start;
    direction: ltr;
    font-family: sans-serif;
    & > span:nth-child(1) {
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
    }
    & > span:nth-child(2) {
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
      margin: 0px 10px;
    }
    & > span:nth-child(3) {
      font-weight: bold;
      font-size: 12px;
      line-height: 31px;
      color: #000000;
      text-decoration: line-through;
    }
  }
  & > .offPercent {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #e45803;
    width: 55px;
    height: 62px;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 8px 0px 0px;
    font-family: sans-serif;
  }

  & > .suggestContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #e45803;
    width: 140px;
    height: 50px;
    font-weight: bold;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-size: 12px;
      line-height: 20px;
      margin: 0px;
      text-align: center;
    }
  }
  & > .favoriteContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.5);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    & > img {
      width: 30px;
      height: 30px;
    }
  }
}

.toomarCartContainer:hover {
  transform: scaleX(1.05);
  box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
  & > img {
    border-radius: 8px 8px 0px 0px;
  }
}

.toomarBlogCardContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  margin: 0px auto 80px;
  background: #ffffff;
  box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
  border-radius: 10px;
  padding-bottom: 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
  & > img {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  & > p {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-align: left;
    width: 80%;
    margin: 5px 20% 20px 10px;
  }
  & > .more {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    direction: rtl;
    padding-left: 30px;
    cursor: pointer;
    & > span:nth-child(1) {
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
      margin-left: 10px;
    }
  }
  & > .date {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #c69c61;
    width: 130px;
    height: 62px;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
}

.toomarBlogCardContainer:hover {
  transform: scale(1.05);
  box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
}

.toomarCardGalleryContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  margin: 0px auto 80px;
  background: #ffffff;
  box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
  border-radius: 10px;
  // padding-bottom: 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
  @media (max-width: 800px) {
    margin-bottom: 30px;
  }
  & > a {
    width: 100%;
  }
  & > a > img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
  }
  & > a > div {
    width: 100%;
  }
  & > a > .overflowTitle {
    height: 87px;
  }
  & > a > div > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    font-family: sans-serif;
    color: #000000;
    text-align: left;
    width: 80%;
    margin: 5px 20% 20px 10px;
    direction: ltr;
  }
  & > a > .footerCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: auto;
    direction: ltr;
  }
  & > a > .footerCards > .productCode > span {
    font-weight: bold;
    font-size: 12px;
    line-height: 31px;
    color: #000000;
    font-family: sans-serif;
  }
  & > a > .footerCards > .price {
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: 100%;
    justify-content: space-between;
    direction: ltr;
    font-family: sans-serif;
    & > span:nth-child(1) {
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
    }
    & > span:nth-child(2) {
      font-weight: bold;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
      margin: 0px 10px;
    }
    & > span:nth-child(3) {
      font-weight: bold;
      font-size: 12px;
      line-height: 31px;
      color: #000000;
      text-decoration: line-through;
    }
  }
  & > a > .offPercent {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #e45803;
    width: 55px;
    height: 62px;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 8px 0px 0px;
    font-family: sans-serif;
  }

  & > a > .suggestContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #e45803;
    width: 140px;
    height: 50px;
    font-weight: bold;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-size: 12px;
      line-height: 20px;
      margin: 0px;
    }
  }
  & > .favoriteContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.5);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    & > img {
      width: 30px;
      height: 30px;
    }
  }
}

.toomarCardGalleryContainer:hover {
  transform: scaleX(1.05);
  box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
  & > a > img {
    border-radius: 8px 8px 0px 0px;
  }
}
