.repetitiveQuestionsContainerPage {
    width: 90%;
    margin: 0px auto 80px;
    border: 2px solid #C69C61;
    box-sizing: border-box;
    border-radius: 30px;
    direction: ltr;
    padding: 40px 30px;
    @media (max-width: 800px) {
        width: calc(100% - 40px);
        margin: 0px auto;
        padding: 40px 10px;
    }
}