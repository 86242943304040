.buyProcessContainer {
  margin-bottom: 20px;
  width: 80%;
  margin: 0px auto;
  & > p {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 20px auto 30px;
    border-bottom: 1px solid rgba(228, 228, 228, 0.63);
    direction: ltr;
    padding-bottom: 20px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 30px;
    }
  }
  @media (max-width: 800px) {
    width: calc(100% - 40px);
    margin: 0px auto;
  }
}

.progressBarContainerForBuyProcess {
  display: flex;
  flex-direction: column;
  direction: ltr;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 98%;
    margin: 0px auto;
    & > div:nth-child(2n + 1) {
      width: 57px;
      height: 57px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f3f3;
      @media (max-width: 800px) {
        width: 40px;
        height: 40px;
      }
      & > img {
        width: 25px;
        display: block;
      }
    }
    & > div:nth-child(2n) {
      width: calc((100% - 228px) / 3);
      background-color: #f3f3f3;
      height: 1px;
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    width: 108%;
    @media (max-width: 800px) {
      width: 98%;
      margin: 0px auto;
    }
    & > p {
      font-family: Aviny;
      font-size: 28px;
      margin: 0px;
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
    & > p:nth-child(1) {
      @media (max-width: 800px) {
        // margin-right: 50px;
        margin-left: 28px;
      }
    }
    & > p:nth-child(2) {
      @media (max-width: 800px) {
        margin-left: 16px;
        // margin-right: 50px;
      }
    }
    & > p:nth-child(3) {
      @media (max-width: 800px) {
        // margin-right: 50px;
      }
    }
  }
}

.progressBarContainerForBuyProcessDigital {
  display: flex;
  flex-direction: column;
  direction: ltr;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 98%;
    margin: 0px auto;
    & > div:nth-child(2n + 1) {
      width: 57px;
      height: 57px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f3f3;
      @media (max-width: 800px) {
        width: 40px;
        height: 40px;
      }
      & > img {
        width: 25px;
        display: block;
      }
    }
    & > div:nth-child(2n) {
      width: calc((100% - 228px) / 2);
      background-color: #f3f3f3;
      height: 1px;
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    width: 98%;
    @media (max-width: 800px) {
      width: 80%;
      margin: 0px auto;
    }
    & > p {
      font-family: Aviny;
      font-size: 28px;
      margin: 0px;
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
    & > p:nth-child(1) {
      @media (max-width: 800px) {
        // margin-right: 50px;
        margin-left: 12px;
      }
    }
    & > p:nth-child(2) {
      @media (max-width: 800px) {
        margin-left: 16px;
        // margin-right: 50px;
      }
    }
    & > p:nth-child(3) {
      @media (max-width: 800px) {
        // margin-right: 50px;
      }
    }
  }
}

.selectTextContainer {
  & > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px auto 30px;
    width: 100%;
    direction: ltr;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
    }
    & > p {
      font-family: Aviny;
      font-size: 24px;
      color: #000000;
      margin: 0px 0px 0px 40px;
      @media (max-width: 800px) {
        margin: auto;
      }
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      direction: ltr;
      @media (max-width: 800px) {
        width: 100%;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 66px;
        margin-left: 50px;
        cursor: pointer;
        @media (max-width: 800px) {
          width: 20%;
          margin-left: 0px;
        }
        & > .isNotActive {
          font-weight: bold;
          font-size: 18px;
          color: black;
          margin: 0px 0px 10px;
          @media (max-width: 800px) {
            font-size: 14px;
          }
        }
        & > .isActive {
          font-weight: bold;
          font-size: 18px;
          color: #eaba77;
          margin: 0px 0px 10px;
          @media (max-width: 800px) {
            font-size: 14px;
          }
        }
        & > img {
          width: 100%;
        }
      }
    }
  }
  & > p:nth-child(2) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }
  & > div:nth-child(3)::-webkit-scrollbar {
    display: none;
  }
  & > div:nth-child(3) {
    display: grid;
    grid-template-columns: calc((100% - 60px) / 3) calc((100% - 60px) / 3) calc(
        (100% - 60px) / 3
      );
    column-gap: 30px;
    direction: ltr;
    margin-bottom: 40px;
    row-gap: 50px;
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    align-items: flex-start;
    & > button:nth-child(1) {
      width: 228px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: IRANYekan;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 20px;
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }
    & > button:nth-child(1):focus {
      outline: none;
    }
    & > button:nth-child(1) {
      background-color: #e15a04;
      border: none;
      color: white;
    }
    & > button:nth-child(1):focus {
      background-color: white;
      border: 1px solid #e45803;
      color: #e45803;
    }
    @media (max-width: 800px) {
      grid-template-columns: 100%;
      column-gap: 0px;
      row-gap: 50px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > img {
        width: 100%;
        display: block;
      }
      & > button {
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        width: 228px;
        height: 68px;
        background: #e45803;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
      }
      & > button:focus {
        outline: none;
      }
      & > button:hover {
        background-color: white;
        border: 1px solid #e45803;
        color: #e45803;
      }
    }
  }
  & > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    direction: ltr;
    margin-bottom: 30px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > button {
      width: 228px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: IRANYekan;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }
    & > button:focus {
      outline: none;
    }
    & > button:nth-child(2) {
      border: 1px solid #e45803;
      color: #e45803;
      background-color: white;
    }
    & > button:nth-child(1) {
      background-color: #e15a04;
      border: none;
      color: white;
    }
    & > button:hover {
      background-color: white;
      border: 1px solid #e45803;
      color: #e45803;
    }
  }
}

.selectTitleContainer {
  & > .titleName {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > .typeOfPrint {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 370px;
    margin: 20px auto;
    @media (max-width: 800px) {
      width: 100%;
    }
    & > button {
      width: 174px;
      height: 51px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 10px;
      color: black;
      font-family: Aviny;
      font-size: 24px;
      background-color: white;
      cursor: pointer;
      @media (max-width: 800px) {
        width: 45%;
        font-size: 20px;
      }
    }
    & > button:focus {
      outline: none;
    }
    & > button:hover {
      background-color: black;
      color: white;
    }
  }
  & > .typeOfPlan {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    direction: ltr;
    margin: 20px auto;
    @media (max-width: 800px) {
      width: 100%;
      flex-direction: column;
    }
    & > button {
      width: 250px;
      height: 51px;
      box-sizing: border-box;
      font-family: Aviny;
      font-size: 24px;
      border-radius: 10px;
      cursor: pointer;
      color: white;
      @media (max-width: 800px) {
        width: 80%;
        margin-bottom: 20px;
      }
    }

    & > button:focus {
      outline: none;
    }
  }
  & > .typeOfPrintLatin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 850px;
    margin: 20px auto;
    direction: ltr;
    @media (max-width: 800px) {
      width: 100%;
      flex-direction: column;
    }
    & > button {
      width: 264px;
      height: 51px;
      box-sizing: border-box;
      font-family: Aviny;
      font-size: 24px;
      border-radius: 10px;
      cursor: pointer;
      color: white;
      @media (max-width: 800px) {
        width: 80%;
        margin-bottom: 20px;
      }
    }
    & > button:nth-child(2) {
      border: 1px solid #dc9300;
      background-color: #dc9300;
    }
    & > button:nth-child(2):hover {
      background-color: white;
      border: 1px solid #dc9300;
      color: #dc9300;
    }
    & > button:nth-child(3) {
      border: 1px solid #747474;
      background-color: #747474;
    }
    & > button:nth-child(3):hover {
      background-color: white;
      border: 1px solid #747474;
      color: #747474;
    }
    & > button:nth-child(1) {
      border: 1px solid black;
      background-color: black;
    }
    & > button:nth-child(1):hover {
      background-color: white;
      border: 1px solid black;
      color: black;
    }
    & > button:focus {
      outline: none;
    }
  }
  & > .typeOfTitr {
    width: 100%;
    margin: 20px auto 40px;
    & > .titleType {
      & > .swiper-button-next.swiper-button-disabled,
      .swiper-button-prev.swiper-button-disabled {
        color: black;
      }
      & > .swiper-button-next,
      .swiper-rtl .swiper-button-prev {
        color: black !important;
      }
      & > .swiper-button-prev,
      .swiper-rtl .swiper-button-next {
        color: black !important;
      }
      & > .swiper-pagination {
        bottom: -5px;
      }
      & > .swiper-pagination > .swiper-pagination-bullet-active {
        background-color: #e45803;
      }
      & > .swiper-wrapper {
        & > div {
          text-align: center;
        }
      }
    }
  }
  & > .backOrNextContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    margin-bottom: 30px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > button {
      width: 228px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: IRANYekan;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }
    & > button:focus {
      outline: none;
    }
    & > button:nth-child(1) {
      border: 1px solid #e45803;
      color: #e45803;
      background-color: white;
    }
    & > button:nth-child(1):hover {
      background-color: #e45803;
      border: 1px solid #e45803;
      color: white;
    }
    & > button:nth-child(2) {
      background-color: #e15a04;
      border: none;
      color: white;
    }
    & > button:nth-child(2):hover {
      background-color: white;
      border: 1px solid #e45803;
      color: #e45803;
    }
  }
}

.addDataContainer {
  & > p:nth-child(1) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > div:nth-child(2) {
    display: grid;
    grid-template-columns: calc((100% - 60px) / 3) calc((100% - 60px) / 3) calc(
        (100% - 60px) / 3
      );
    column-gap: 30px;
    margin-bottom: 30px;
    direction: ltr;
    @media (max-width: 800px) {
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      row-gap: 20px;
      column-gap: 20px;
    }
  }
  & > p:nth-child(3) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > div:nth-child(4) {
    display: grid;
    grid-template-columns: calc((100% - 60px) / 3) calc((100% - 60px) / 3) calc(
        (100% - 60px) / 3
      );
    column-gap: 30px;
    margin-bottom: 30px;
    direction: ltr;
    @media (max-width: 800px) {
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      row-gap: 20px;
      column-gap: 20px;
    }
  }
  & > p:nth-child(5) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > div:nth-child(6) {
    direction: ltr;
    margin: 0px 0px 30px;
    & > select {
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: IRANYekan;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      color: #000000;
      padding: 0px 20px;
      @media (max-width: 800px) {
        font-size: 14px;
        height: 40px;
      }
    }
    & > select:focus {
      outline: none;
    }
  }
  & > p:nth-child(7) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > div:nth-child(8) {
    direction: ltr;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin-right: 30%;
    margin-bottom: 20px;
    @media (max-width: 800px) {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0px;
      flex-direction: column;
      align-items: flex-start;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      color: #000000;
      margin: 0px;
      @media (max-width: 800px) {
        font-size: 18px;
      }
    }
    & > div {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      & > div > input {
        width: 90% !important;
      }
      & > div > input:focus {
        outline: none;
      }
    }
  }
  & > div:nth-child(9) {
    direction: ltr;
    margin: 0px 0px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
      }
      & > p {
        font-family: Aviny;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        color: #000000;
        margin: 0px;
        @media (max-width: 800px) {
          font-size: 18px;
        }
      }
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 800px) {
          flex-direction: column;
          align-items: flex-start;
        }
        & > div > select {
          height: 53px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border: 1px solid #e4e4e4;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: IRANYekan;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: #000000;
          padding: 0px 20px;
          margin: 0px 15px;
          @media (max-width: 800px) {
            font-size: 14px;
            height: 40px;
          }
        }
        & > div > select:focus {
          outline: none;
        }
        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          @media (max-width: 800px) {
            margin-top: 20px;
          }

          & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            color: #000000;
            margin: 0px 0px 0px 30px;
            @media (max-width: 800px) {
              font-size: 18px;
            }
          }
        }
      }
    }
    & > div:nth-child(2) {
      margin-left: 30px;
      @media (max-width: 800px) {
        margin-left: 0px;
      }
    }
  }
  & > div:nth-child(10) {
    margin-bottom: 40px;
    & > div > input {
      width: 60%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
  & > p:nth-child(11) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > div:nth-child(12) {
    display: grid;
    grid-template-columns: calc((100% - 40px) / 2) calc((100% - 40px) / 2);
    column-gap: 40px;
    margin-bottom: 30px;
    direction: ltr;
    width: 70%;
    margin-left: 30%;
    @media (max-width: 800px) {
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      column-gap: 20px;
      width: 100%;
      margin-left: 0px;
    }
    & > div > input {
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
  & > div:nth-child(13) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    margin-bottom: 30px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > button {
      width: 228px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: IRANYekan;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }
    & > button:focus {
      outline: none;
    }
    & > button:nth-child(1) {
      border: 1px solid #e45803;
      color: #e45803;
      background-color: white;
    }
    & > button:nth-child(1):hover {
      background-color: #e45803;
      border: 1px solid #e45803;
      color: white;
    }
    & > button:nth-child(2) {
      background-color: #e15a04;
      border: none;
      color: white;
    }
    & > button:nth-child(2):hover {
      background-color: white;
      border: 1px solid #e45803;
      color: #e45803;
    }
  }
}

.acceptDataContainer {
  & > p:nth-child(1) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(228, 228, 228, 0.63);
      direction: ltr;
      padding-bottom: 15px;
      margin-bottom: 15px;
      & > p {
        font-family: Aviny;
        font-size: 30px;
        color: #000000;
        margin: 0px;
        width: 200px;
        @media (max-width: 800px) {
          font-size: 16px;
        }
      }
      & > p:nth-child(2) {
        display: flex;
        justify-content: center;
      }
      & > p:nth-child(3) {
        display: flex;
        justify-content: flex-end;
      }
      & > div {
        width: 200px;
        & > img {
          width: 30px;
          cursor: pointer;
          display: block;
          @media (max-width: 800px) {
            width: 24px;
          }
        }
      }
      & > div:nth-child(2) {
        display: flex;
        justify-content: center;
      }
      & > div:nth-child(3) {
        display: flex;
        justify-content: flex-end;
      }
    }
    & > div:nth-child(1) {
      & > p {
        color: #916f40;
      }
    }
    & > div:last-child {
      border-bottom: none;
    }
  }
  & > p:nth-child(3) {
    font-family: Aviny;
    font-size: 36px;
    color: #000000;
    margin: 0px 0px 20px 0px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 26px;
      margin-top: 20px;
    }
  }
  & > p:nth-child(4),
  p:nth-child(5),
  p:nth-child(6),
  p:nth-child(7),
  p:nth-child(8),
  p:nth-child(9),
  p:nth-child(10),
  p:nth-child(11),
  p:nth-child(12),
  :nth-child(13),
  :nth-child(14) {
    font-family: IRANYekan;
    font-size: 18px;
    text-align: left;
    color: #000000;
    margin: 0px 0px 30px;
    direction: ltr;
    @media (max-width: 800px) {
      font-size: 14px;
    }
  }
  & > div:nth-child(15) {
    direction: ltr;
    margin: 0px 0px 40px;
    & > div > p {
      color: #e45803;
    }
  }
  & > div:nth-child(16) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    margin-bottom: 30px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > button {
      width: 228px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: IRANYekan;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }
    & > button:focus {
      outline: none;
    }
    & > button:nth-child(1) {
      border: 1px solid #e45803;
      color: #e45803 !important;
      background-color: white;
    }
    & > button:nth-child(2) {
      background-color: #e15a04;
      border: none;
      color: white;
    }
  }
}

.changeStepContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  & > button {
    width: 228px;
    height: 52px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: IRANYekan;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    @media (max-width: 800px) {
      margin-bottom: 20px;
    }
  }
  & > button:focus {
    outline: none;
  }
  & > button:nth-child(2) {
    background-color: #e15a04;
    color: white;
    border: none;
    margin-right: 20px;
    @media (max-width: 800px) {
      margin-right: 0px;
    }
  }
  & > button:nth-child(1) {
    border: 1px solid #e45803;
    background-color: white;
    color: #e45803;
  }
}

.selected {
  background-color: lightblue !important;
  color: white;
}

.acceptDetailsReview {
  display: flex;
  flex-direction: column;
  padding: 40px;
  direction: ltr;
  & > .header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
    & > p:nth-child(1) {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      margin: 0px;
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;
      direction: ltr;
      & > p {
        font-family: IRANYekan;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #000000;
        margin: 0px 0px 0px 5px;
      }
      & > img {
        width: 24px;
        height: 24px;
      }
    }
  }
  & > button:last-child {
    width: 228px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-right: calc(100% - 228px);
    border: none;
    background: #e15a04;
    border-radius: 5px;
    cursor: pointer;
    & > p {
      font-family: IRANYekan;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 31px;
      color: #ffffff;
      margin: 0px 10px 0px 0px;
    }
  }
}

.cardTextImage {
  width: 537px;
  margin: 20px auto 40px;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.titleDetailsContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 40px;
  & > div:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin: 0px 0px 20px;
      color: #000000;
    }
    & > button {
      width: 174px;
      height: 51px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 10px;
      color: black;
      font-family: Aviny;
      font-size: 24px;
      background-color: white;
    }
  }
  & > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin: 0px 0px 20px;
      color: #000000;
    }
    & > button {
      width: 250px;
      height: 51px;
      box-sizing: border-box;
      font-family: Aviny;
      font-size: 24px;
      border-radius: 10px;
      color: white;
    }
  }
  & > div:nth-child(3) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 40px 0px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin: 0px 0px 20px;
      color: #000000;
    }
    & > button {
      width: 250px;
      height: 51px;
      box-sizing: border-box;
      font-family: Aviny;
      font-size: 24px;
      border-radius: 10px;
      color: white;
    }
  }
  & > div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 40px 0px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin: 0px 0px 20px;
      color: #000000;
    }
    & > img {
      width: 70%;
    }
  }
}

.dataDetailsContainer {
  display: flex;
  flex-direction: column;
  & > p:nth-child(1) {
    font-family: Aviny;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #937040;
    margin: 0px;
  }
  & > div:nth-child(2) {
    margin: 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 20px;
      row-gap: 20px;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #232323;
      margin: 0px;
    }
  }
  & > p:nth-child(3) {
    font-family: Aviny;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #937040;
    margin: 0px;
  }
  & > div:nth-child(4) {
    margin: 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 20px;
      row-gap: 20px;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #232323;
      margin: 0px;
    }
  }
  & > div:nth-child(5) {
    margin: 0px 0px 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 20px;
      row-gap: 20px;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #232323;
      margin: 0px;
    }
  }
  & > div:nth-child(6) {
    margin: 0px 0px 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 20px;
      row-gap: 20px;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #232323;
      margin: 0px;
    }
    & > p:nth-child(1) {
      margin-right: 50px;
    }
  }
  & > div:nth-child(7) {
    margin: 0px 0px 30px 0px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 20px;
      row-gap: 20px;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #232323;
      margin: 0px;
    }
    & > p:nth-child(1) {
      margin-right: 50px;
    }
  }
}

.black {
  border: 1px solid black;
  background-color: black;
  margin-left: 5px;
  margin-bottom: 5px;
}
.black:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}
.brown {
  border: 1px solid #4f2800;
  background-color: #4f2800;
  margin-left: 5px;
  margin-bottom: 5px;
}
.brown:hover {
  background-color: white;
  border: 1px solid #4f2800;
  color: #4f2800;
}
.red {
  border: 1px solid #a00800;
  background-color: #a00800;
  margin-left: 5px;
  margin-bottom: 5px;
}
.red:hover {
  background-color: white;
  border: 1px solid #a00800;
  color: #a00800;
}
.yellow {
  border: 1px solid #dc9300;
  background-color: #dc9300;
  margin-left: 5px;
  margin-bottom: 5px;
}
.yellow:hover {
  background-color: white;
  border: 1px solid #dc9300;
  color: #dc9300;
}
.gray {
  border: 1px solid #747474;
  background-color: #747474;
  margin-bottom: 5px;
}
.gray:hover {
  background-color: white;
  border: 1px solid #747474;
  color: #747474;
}
.gold {
  border: 1px solid #dc9300;
  background-color: #dc9300;
}
.gold:hover {
  background-color: white;
  border: 1px solid #dc9300;
  color: #dc9300;
}
.silver {
  border: 1px solid #747474;
  background-color: #747474;
  margin-left: 5px;
}
.silver:hover {
  background-color: white;
  border: 1px solid #747474;
  color: #747474;
}

.titleContainerBuyProcess {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > img {
    width: 80%;
    margin: auto;
    height: 200px;
    object-fit: contain;
  }
  & > button {
    font-family: IRANYekan;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    width: 228px;
    height: 68px;
    background: #e45803;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }
  & > button:focus {
    outline: none;
  }
  & > button:hover {
    background-color: white;
    border: 1px solid #e45803;
    color: #e45803;
  }
}

.giftDateContainer {
  direction: ltr;
  margin: 0px 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
      margin-bottom: 10px;
      flex-direction: column;
      align-items: flex-start;
    }
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      color: #000000;
      margin: 0px;
      @media (max-width: 800px) {
        font-size: 18px;
      }
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
      }
      & > div > select {
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #e4e4e4;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: IRANYekan;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        padding: 0px 20px;
        margin: 0px 15px;
        @media (max-width: 800px) {
          font-size: 14px;
          height: 40px;
        }
      }
      & > div > select:focus {
        outline: none;
      }
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 800px) {
          margin-top: 20px;
        }

        & > p {
          font-family: Aviny;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          color: #000000;
          margin: 0px 0px 0px 30px;
          @media (max-width: 800px) {
            font-size: 18px;
          }
        }
      }
    }
  }
  & > div:nth-child(2) {
    margin-right: 30px;
    @media (max-width: 800px) {
      margin-right: 0px;
    }
  }
}
