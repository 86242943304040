.followUpLastOrderContainer {
    width: 90%;
    margin: 0px auto 80px;
    border-radius: 30px;
    direction: ltr;
    padding: 40px 30px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
        margin: 0px auto 80px;
    }
    & > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        & > img {
            width: 120px;
            display: block;
            margin-bottom: 20px;
        }
        & > p {
            font-family: Aviny;
            margin: 0px;
            font-size: 24px;
            color: black;
            & > span {
                color: #BD945B;
            }
        }
        & > p:nth-child(3) {
            margin: 5px 0px;
        }
        & > p:nth-child(4) {
            color: #BD945B;
            font-size: 20px;
        }
        & > div {
            margin-top: 40px;
        }
    }
}