.galleryContainer {
  padding: 0px 40px;
  margin: 0px auto 20px;
  width: 80%;
  @media (max-width: 800px) {
    padding: 0px 20px;
    width: calc(100% - 40px);
  }
  & > img {
    //width: 100%;
    //margin: 40px 0px 0px;
    //@media (max-width: 800px) {
    //    width: calc(100% + 40px);
    //    margin: 40px 0px 0px -20px;
    //    // height: 200px;
    //}
    width: 100%;
  }
  & > .banner-a {
    & > img {
      width: 100%;
      margin: 40px 0px 0px;
      @media (max-width: 800px) {
        width: calc(100% + 40px);
        margin: 40px 0px 0px -20px;
        // height: 200px;
      }
    }
  }
  & > div {
    display: grid;
    grid-template-columns: 229px calc(100% - 279px);
    column-gap: 50px;
    direction: ltr;
    margin: 40px auto 0px;
    width: calc(100% - 50px);
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: 0px;
      column-gap: 0px;
    }
    & > div:first-child {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (max-width: 800px) {
        display: none;
      }
      & > div {
        & > p {
          font-family: Aviny;
          font-size: 28px;
          color: #000000;
          margin: 0px 0px 20px 0px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          width: 100%;
          @media (max-width: 800px) {
            font-size: 20px;
          }
        }
        & > div {
          margin-bottom: 20px;
        }
      }
    }

    & > div:last-child {
      & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > p {
          font-family: Aviny;
          font-size: 20px;
          color: #000000;
          margin: 0px 15px 24px 0px;
          @media (max-width: 800px) {
            display: none;
          }
        }
        & > div {
          margin-left: 0px;
          width: 600px;
          @media (max-width: 800px) {
            width: 100% !important;
          }
        }
      }
      & > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0px;
        @media (max-width: 800px) {
          display: none;
        }
        & > p {
          font-family: Aviny;
          font-size: 24px;
          color: #000000;
          margin: 0px 40px 0px 0px;
          @media (max-width: 800px) {
            font-size: 20px;
          }
        }
        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          direction: ltr;
          @media (max-width: 800px) {
            display: grid;
            grid-template-columns: calc(50% - 10px) calc(50% - 10px);
            row-gap: 20px;
            column-gap: 20px;
            margin: 0px auto;
          }
          & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 66px;
            width: 144px;
            margin-right: 25px;
            cursor: pointer;
            flex-grow: 0;
            @media (max-width: 800px) {
              margin-right: 0px;
            }
            & > .isNotActive {
              font-weight: bold;
              font-size: 14px;
              color: black;
              margin: 0px 0px 10px;
              @media (max-width: 800px) {
                font-size: 14px;
              }
            }
            & > .isActive {
              font-weight: bold;
              font-size: 14px;
              color: #eaba77;
              margin: 0px 0px 10px;
              @media (max-width: 800px) {
                font-size: 14px;
              }
            }
            & > p:hover {
              color: #eaba77;
            }
            & > img {
              width: 100%;
            }
          }
        }
      }
      & > div:nth-child(3) {
        display: none;
        @media (max-width: 800px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 0px 0px 20px;
        }
        & > button {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #eeeeee;
          height: 44px;
          width: 200px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          & > img {
            margin-left: 5px;
          }
          & > p {
            font-family: Aviny;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #000000;
            margin: 0px;
          }
        }
        & > select {
          font-family: Aviny;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #000000;
          padding: 0px 20px;
          background-color: #eeeeee;
          height: 44px;
          width: 120px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      & > div:nth-child(4) {
        display: grid;
        grid-template-columns: calc(32.33333% - 10px) calc(32.33333% - 10px) calc(
            32.3333% - 10px
          );
        column-gap: 15px;
        row-gap: 15px;
        margin-bottom: 30px;
        -ms-overflow-style: none;
        @media (max-width: 800px) {
          display: flex;
          flex-direction: column;
        }
        & > div > a > div {
          width: 100%;
          margin-bottom: 30px;
          height: auto;
          & > img {
            height: auto;
          }
        }
        & > div {
          width: 100%;
          margin-bottom: 0px;
        }
        & > button:last-child {
          width: 200px;
          text-align: center;
          padding: 10px 0px;
          background-color: #e15a04;
          color: white;
          font-weight: bold;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          font-family: IRANYekan;
          margin-left: calc(150% - 100px);
          @media (max-width: 800px) {
            margin-left: calc(50% - 100px);
          }
        }
      }
      & > div:nth-child(3)::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.categoriesContainer {
  & > .parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
    & > p {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      margin: 0px;
    }
  }
  & > .childrenGallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px auto 10px;
    cursor: pointer;
    & > p:nth-child(1) {
      font-size: 14px;
      margin: 0px;
      line-height: 24px;
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      & > p {
        font-size: 14px;
        margin: 4px 0px;
        line-height: 24px;
      }
    }
  }
}

.hintModalContainer {
  display: flex;
  flex-direction: column;
  direction: ltr;
  padding: 40px 50px 90px;
  @media (max-width: 800px) {
    padding: 40px 20px;
  }
  // & > div:nth-child(1) {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: flex-end;
  //     & > p {
  //         font-family: IRANYekan;
  //         font-style: normal;
  //         font-weight: 700;
  //         font-size: 16px;
  //         line-height: 28px;
  //         color: #000000;
  //         margin: 0px 0px 0px 5px;
  //     }
  // }
  & > img:nth-child(1) {
    width: 72px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    margin: 20px auto;
  }
  & > p:nth-child(2) {
    font-family: Aviny;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    color: #000000;
    margin: 0px auto 10px auto;
    width: 100%;
    text-align: center;
  }
  & > p:nth-child(3) {
    font-family: Aviny;
    font-style: normal;
    font-size: 16px;
    color: red;
    margin: 0px auto 10px auto;
    width: 100%;
    text-align: center;
  }
  & > button:nth-child(4) {
    background-color: #e15a04;
    border-radius: 8px;
    width: 200px;
    margin: 12px auto 0px;
    border: none;
    padding: 8px 0px;
    font-family: Aviny;
    font-size: 16px;
    cursor: pointer;
    @media (max-width: 800px) {
      width: 80%;
    }
  }
  // & > p:nth-child(3) {
  //     font-family: Aviny;
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 24px;
  //     line-height: 38px;
  //     color: #000000;
  //     margin: 0px auto 10px auto;
  //     width: 100%;
  //     text-align: center;
  // }
  // & > p:nth-child(4) {
  //     font-family: Aviny;
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 24px;
  //     line-height: 38px;
  //     color: #000000;
  //     margin: 0px auto 0px auto;
  //     width: 100%;
  //     text-align: center;
  // }
}

.categoriesModalContainer {
  padding: 30px;
  direction: ltr;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  & > div:last-child {
    & > p {
      font-family: Aviny;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 38px;
      color: #000000;
      margin: 10px 0px 20px;
    }
  }
}
