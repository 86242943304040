.favoritesContainer {
    width: 90%;
    margin: 0px auto 80px;
    border-radius: 30px;
    direction: ltr;
    padding: 40px 30px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        width: calc(100% - 40px);
        margin: 0px auto 80px;
    }
    & > p {
        font-family: Aviny;
        font-style: normal;
        font-size: 24px;
        text-align: left;
        color: #000000;
        margin: 0px 0px 20px;
        @media (max-width: 800px) {
            font-size: 20px;
        }
    }
    & > div {
        display: grid;
        grid-template-columns: calc(32.33333% - 10px) calc(32.33333% - 10px) calc(32.3333% - 10px);
        column-gap: 15px;
        row-gap: 15px;
        & > div {
            width: 100%;
        }
        @media (max-width: 800px) {
            grid-template-columns: 100%;
        }
        // display: grid;
        // grid-template-columns: 33.333% 33.333% 33.333%;
        // row-gap: 20px;
    }
    // & > div {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: space-between;
    //     margin-bottom: 10px;
    //     @media (max-width: 800px) {
    //         flex-direction: column;
    //     }
    //     & > a > div:nth-child(1) {
    //         display: flex;
    //         flex-direction: row;
    //         width: 100%;
    //         // align-items: center;
    //         @media (max-width: 800px) {
    //             flex-direction: column;
    //         }
    //         & > img {
    //             width: 300px;
    //             height: 239px;
    //             object-fit: cover;
    //             border-radius: 8px;
    //             @media (max-width: 800px) {
    //                 width: 100%;
    //             }
    //         }
    //         & > div {
    //             display: flex;
    //             flex-direction: column;
    //             margin: 0px 8px 0px 0px;
    //             justify-content: space-between;
    //             & > p:nth-child(1) {
    //                 font-size: 18px;
    //                 margin: 0px;
    //                 color: black;
    //                 @media (max-width: 800px) {
    //                     font-size: 14px;
    //                     margin: 8px 0px;
    //                 }
    //             }
    //             & > p:nth-child(2) {
    //                 font-size: 18px;
    //                 margin: 8px 0px 0px;
    //                 color: black;
    //                 @media (max-width: 800px) {
    //                     font-size: 14px;
    //                     margin: 0px 0px 8px;
    //                 }
    //             }
    //         }
    //     }
    // }
}

.priceFavorites {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    direction: ltr;
    & > span:nth-child(1) {
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
    }
    & > span:nth-child(2) {
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
        margin: 0px 10px;
    }
    & > span:nth-child(3) {
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        color: #000000;
        text-decoration: line-through;
    }
}

.deleteContainer {
    cursor: pointer;
}


.favCardContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    // height: 100%;
    margin: 0px auto 20px;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    // padding-bottom: 30px;
    position: relative;
    transition: all .2s ease-in-out; 
    @media (max-width: 800px) {
        margin-bottom: 30px;
    }
    & > a {
        width: 100%;
    }
    & > a > img {
        width: 100%;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }
    & > a > div {
        width: 100%;
    }
    & > a > .overflowTitle {
        height: 87px;
    }
    & > a > div > p {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        text-align: left;
        width: 80%;
        margin: 5px 20% 20px 10px;
        direction: ltr;
    }
    & > a > .footerCards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        direction: ltr;
        margin: auto;
    }
    & > a > .footerCards > .productCode > span {
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        color: #000000;
        font-family: sans-serif;
    }
    & > a > .footerCards > .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        direction: ltr;
        font-family: sans-serif;
        & > span:nth-child(1) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
        }
        & > span:nth-child(2) {
            font-weight: bold;
            font-size: 18px;
            line-height: 31px;
            color: #000000;
            margin: 0px 10px;
        }
        & > span:nth-child(3) {
            font-weight: bold;
            font-size: 12px;
            line-height: 31px;
            color: #000000;
            text-decoration: line-through;
        }
    }
    & > a > .offPercent {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 55px;
        height: 62px;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 0px 0px;
    }

    & > a > .suggestContainer {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #E45803;
        width: 140px;
        height: 50px;
        font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        & > p {
            font-size: 12px;
            line-height: 20px;
            margin: 0px;
        }
    }
    & > .favoriteContainer {
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(255, 255, 255, 0.5);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        z-index: 50;
        & > img {
            width: 30px;
            height: 30px;
        }
    }

}

.favCardContainer:hover {
    transform: scaleX(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
    & > a > img {
        border-radius: 8px 8px 0px 0px;
    }
}